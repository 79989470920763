import { derived, writable } from 'svelte/store';
import { createSlotTimeLimits, createTimes, setContent, toLocalDate, max, toISOString, keyEnter, bgEvent, helperEvent, toEventWithLocalDates, toViewWithLocalDates, task, toSeconds, DAY_IN_SECONDS, createEventClasses, createEventContent, height, datesEqual, ceil, addDuration, cloneDate, runReposition, setPayload, rect, floor, addDay, eventIntersects, createEventChunk, prepareEventChunks, debounce, btnTextDay, themeView, btnTextWeek, viewResources } from '@event-calendar/core';
import { SvelteComponent, init, safe_not_equal, element, insert, action_destroyer, is_function, noop, detach, component_subscribe, binding_callbacks, ensure_array_like, space, attr, set_style, append, transition_in, group_outros, check_outros, transition_out, destroy_each, create_component, mount_component, destroy_component, construct_svelte_component, listen, run_all, update_keyed_each, outro_and_destroy_block, empty, set_store_value } from 'svelte/internal';
import { getContext, onMount, afterUpdate } from 'svelte';

// slotTimeLimits per day
function dayTimeLimits(state) {
    return derived(
        [state.slotMinTime, state.slotMaxTime, state.flexibleSlotTimeLimits, state._viewDates, state._events],
        ([$slotMinTime, $slotMaxTime, $flexibleSlotTimeLimits, $_viewDates, $_events]) => {
            let dayTimeLimits = {};
            for (let date of $_viewDates) {
                dayTimeLimits[date.getTime()] = createSlotTimeLimits(
                    $slotMinTime,
                    $slotMaxTime,
                    $flexibleSlotTimeLimits,
                    [date],
                    $_events
                );
            }

            return dayTimeLimits;
        }
    );
}

function dayTimes(state) {
    return derived(
        [state._viewDates, state.slotDuration, state._dayTimeLimits, state._intlSlotLabel, state._intlDayHeaderAL],
        ([$_viewDates, $slotDuration, $_dayTimeLimits, $_intlSlotLabel, $_intlDayHeaderAL]) => {
            let dayTimes = {};
            for (let date of $_viewDates) {
                let time = date.getTime();
                dayTimes[time] = time in $_dayTimeLimits
                    ? createTimes(date, $slotDuration, $_dayTimeLimits[time], $_intlSlotLabel, $_intlDayHeaderAL)
                    : [];
            }

            return dayTimes;
        }
    );
}

/* packages/resource-timeline/src/Label.svelte generated by Svelte v4.2.16 */

function create_fragment$7(ctx) {
	let span;
	let setContent_action;
	let mounted;
	let dispose;

	return {
		c() {
			span = element("span");
		},
		m(target, anchor) {
			insert(target, span, anchor);
			/*span_binding*/ ctx[7](span);

			if (!mounted) {
				dispose = action_destroyer(setContent_action = setContent.call(null, span, /*content*/ ctx[1]));
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (setContent_action && is_function(setContent_action.update) && dirty & /*content*/ 2) setContent_action.update.call(null, /*content*/ ctx[1]);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) {
				detach(span);
			}

			/*span_binding*/ ctx[7](null);
			mounted = false;
			dispose();
		}
	};
}

function instance$7($$self, $$props, $$invalidate) {
	let $resourceLabelDidMount;
	let $resourceLabelContent;
	let { resource } = $$props;
	let { date = undefined } = $$props;
	let { resourceLabelContent, resourceLabelDidMount } = getContext('state');
	component_subscribe($$self, resourceLabelContent, value => $$invalidate(6, $resourceLabelContent = value));
	component_subscribe($$self, resourceLabelDidMount, value => $$invalidate(8, $resourceLabelDidMount = value));
	let el;
	let content;

	onMount(() => {
		if (is_function($resourceLabelDidMount)) {
			$resourceLabelDidMount({
				resource,
				date: date ? toLocalDate(date) : undefined,
				el
			});
		}
	});

	function span_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(0, el);
		});
	}

	$$self.$$set = $$props => {
		if ('resource' in $$props) $$invalidate(4, resource = $$props.resource);
		if ('date' in $$props) $$invalidate(5, date = $$props.date);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$resourceLabelContent, resource, date*/ 112) {
			// Content
			if ($resourceLabelContent) {
				$$invalidate(1, content = is_function($resourceLabelContent)
				? $resourceLabelContent({
						resource,
						date: date ? toLocalDate(date) : undefined
					})
				: $resourceLabelContent);
			} else {
				$$invalidate(1, content = resource.title);
			}
		}
	};

	return [
		el,
		content,
		resourceLabelContent,
		resourceLabelDidMount,
		resource,
		date,
		$resourceLabelContent,
		span_binding
	];
}

class Label extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$7, create_fragment$7, safe_not_equal, { resource: 4, date: 5 });
	}
}

/* packages/resource-timeline/src/Sidebar.svelte generated by Svelte v4.2.16 */

function get_each_context$4(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[12] = list[i];
	return child_ctx;
}

// (18:8) {#each $_viewResources as resource}
function create_each_block$4(ctx) {
	let div;
	let label;
	let t;
	let div_class_value;
	let current;

	label = new Label({
			props: { resource: /*resource*/ ctx[12] }
		});

	return {
		c() {
			div = element("div");
			create_component(label.$$.fragment);
			t = space();
			attr(div, "class", div_class_value = /*$theme*/ ctx[1].resource);
			set_style(div, "flex-basis", max(/*$_resHs*/ ctx[4].get(/*resource*/ ctx[12]) ?? 0, 34) + "px");
			attr(div, "role", "rowheader");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(label, div, null);
			append(div, t);
			current = true;
		},
		p(ctx, dirty) {
			const label_changes = {};
			if (dirty & /*$_viewResources*/ 8) label_changes.resource = /*resource*/ ctx[12];
			label.$set(label_changes);

			if (!current || dirty & /*$theme*/ 2 && div_class_value !== (div_class_value = /*$theme*/ ctx[1].resource)) {
				attr(div, "class", div_class_value);
			}

			if (!current || dirty & /*$_resHs, $_viewResources*/ 24) {
				set_style(div, "flex-basis", max(/*$_resHs*/ ctx[4].get(/*resource*/ ctx[12]) ?? 0, 34) + "px");
			}
		},
		i(local) {
			if (current) return;
			transition_in(label.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(label.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			destroy_component(label);
		}
	};
}

function create_fragment$6(ctx) {
	let div2;
	let div0;
	let div0_class_value;
	let t;
	let div1;
	let div1_class_value;
	let div2_class_value;
	let current;
	let each_value = ensure_array_like(/*$_viewResources*/ ctx[3]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block$4(get_each_context$4(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			t = space();
			div1 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div0, "class", div0_class_value = /*$theme*/ ctx[1].sidebarTitle);
			set_style(div0, "flex-basis", /*titleHeight*/ ctx[0] + "px");
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[1].content);
			attr(div2, "class", div2_class_value = /*$theme*/ ctx[1].sidebar);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div2, t);
			append(div2, div1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div1, null);
				}
			}

			/*div1_binding*/ ctx[11](div1);
			current = true;
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*$theme*/ 2 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[1].sidebarTitle)) {
				attr(div0, "class", div0_class_value);
			}

			if (!current || dirty & /*titleHeight*/ 1) {
				set_style(div0, "flex-basis", /*titleHeight*/ ctx[0] + "px");
			}

			if (dirty & /*$theme, $_resHs, $_viewResources*/ 26) {
				each_value = ensure_array_like(/*$_viewResources*/ ctx[3]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context$4(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block$4(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div1, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (!current || dirty & /*$theme*/ 2 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[1].content)) {
				attr(div1, "class", div1_class_value);
			}

			if (!current || dirty & /*$theme*/ 2 && div2_class_value !== (div2_class_value = /*$theme*/ ctx[1].sidebar)) {
				attr(div2, "class", div2_class_value);
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div2);
			}

			destroy_each(each_blocks, detaching);
			/*div1_binding*/ ctx[11](null);
		}
	};
}

function instance$6($$self, $$props, $$invalidate) {
	let $_headerEl;
	let $theme;
	let $_sidebarEl;
	let $_viewResources;
	let $_resHs;
	let { _viewResources, _headerEl, _resHs, _sidebarEl, theme } = getContext('state');
	component_subscribe($$self, _viewResources, value => $$invalidate(3, $_viewResources = value));
	component_subscribe($$self, _headerEl, value => $$invalidate(10, $_headerEl = value));
	component_subscribe($$self, _resHs, value => $$invalidate(4, $_resHs = value));
	component_subscribe($$self, _sidebarEl, value => $$invalidate(2, $_sidebarEl = value));
	component_subscribe($$self, theme, value => $$invalidate(1, $theme = value));
	let titleHeight = 0;

	function div1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			$_sidebarEl = $$value;
			_sidebarEl.set($_sidebarEl);
		});
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$_headerEl*/ 1024) {
			if ($_headerEl) {
				$$invalidate(0, titleHeight = $_headerEl.clientHeight);
			}
		}
	};

	return [
		titleHeight,
		$theme,
		$_sidebarEl,
		$_viewResources,
		$_resHs,
		_viewResources,
		_headerEl,
		_resHs,
		_sidebarEl,
		theme,
		$_headerEl,
		div1_binding
	];
}

class Sidebar extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$6, create_fragment$6, safe_not_equal, {});
	}
}

/* packages/resource-timeline/src/Header.svelte generated by Svelte v4.2.16 */

function get_each_context$3(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[11] = list[i];
	return child_ctx;
}

function get_each_context_1$2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[14] = list[i];
	return child_ctx;
}

// (19:20) {#each $_dayTimes[date.getTime()] as time}
function create_each_block_1$2(ctx) {
	let div;
	let time_1;
	let time_1_datetime_value;
	let setContent_action;
	let t;
	let div_class_value;
	let div_aria_label_value;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			time_1 = element("time");
			t = space();
			attr(time_1, "datetime", time_1_datetime_value = /*time*/ ctx[14][0]);
			attr(div, "class", div_class_value = /*$theme*/ ctx[0].time);
			attr(div, "aria-label", div_aria_label_value = /*time*/ ctx[14][3]);
			attr(div, "role", "columnheader");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, time_1);
			append(div, t);

			if (!mounted) {
				dispose = action_destroyer(setContent_action = setContent.call(null, time_1, /*time*/ ctx[14][1]));
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*$_dayTimes, $_viewDates*/ 20 && time_1_datetime_value !== (time_1_datetime_value = /*time*/ ctx[14][0])) {
				attr(time_1, "datetime", time_1_datetime_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty & /*$_dayTimes, $_viewDates*/ 20) setContent_action.update.call(null, /*time*/ ctx[14][1]);

			if (dirty & /*$theme*/ 1 && div_class_value !== (div_class_value = /*$theme*/ ctx[0].time)) {
				attr(div, "class", div_class_value);
			}

			if (dirty & /*$_dayTimes, $_viewDates*/ 20 && div_aria_label_value !== (div_aria_label_value = /*time*/ ctx[14][3])) {
				attr(div, "aria-label", div_aria_label_value);
			}
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			mounted = false;
			dispose();
		}
	};
}

// (10:8) {#each $_viewDates as date}
function create_each_block$3(ctx) {
	let div2;
	let div0;
	let time_1;
	let time_1_datetime_value;
	let setContent_action;
	let div0_class_value;
	let t0;
	let div1;
	let div1_class_value;
	let t1;
	let div2_class_value;
	let mounted;
	let dispose;
	let each_value_1 = ensure_array_like(/*$_dayTimes*/ ctx[4][/*date*/ ctx[11].getTime()]);
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1$2(get_each_context_1$2(ctx, each_value_1, i));
	}

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			time_1 = element("time");
			t0 = space();
			div1 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t1 = space();
			attr(time_1, "datetime", time_1_datetime_value = toISOString(/*date*/ ctx[11], 10));
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[0].dayHead);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[0].times);
			attr(div2, "class", div2_class_value = "" + (/*$theme*/ ctx[0].day + " " + /*$theme*/ ctx[0].weekdays?.[/*date*/ ctx[11].getUTCDay()]));
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div0, time_1);
			append(div2, t0);
			append(div2, div1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div1, null);
				}
			}

			append(div2, t1);

			if (!mounted) {
				dispose = action_destroyer(setContent_action = setContent.call(null, time_1, /*$_intlDayHeader*/ ctx[3].format(/*date*/ ctx[11])));
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*$_viewDates*/ 4 && time_1_datetime_value !== (time_1_datetime_value = toISOString(/*date*/ ctx[11], 10))) {
				attr(time_1, "datetime", time_1_datetime_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty & /*$_intlDayHeader, $_viewDates*/ 12) setContent_action.update.call(null, /*$_intlDayHeader*/ ctx[3].format(/*date*/ ctx[11]));

			if (dirty & /*$theme*/ 1 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[0].dayHead)) {
				attr(div0, "class", div0_class_value);
			}

			if (dirty & /*$theme, $_dayTimes, $_viewDates*/ 21) {
				each_value_1 = ensure_array_like(/*$_dayTimes*/ ctx[4][/*date*/ ctx[11].getTime()]);
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1$2(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_1$2(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div1, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_1.length;
			}

			if (dirty & /*$theme*/ 1 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[0].times)) {
				attr(div1, "class", div1_class_value);
			}

			if (dirty & /*$theme, $_viewDates*/ 5 && div2_class_value !== (div2_class_value = "" + (/*$theme*/ ctx[0].day + " " + /*$theme*/ ctx[0].weekdays?.[/*date*/ ctx[11].getUTCDay()]))) {
				attr(div2, "class", div2_class_value);
			}
		},
		d(detaching) {
			if (detaching) {
				detach(div2);
			}

			destroy_each(each_blocks, detaching);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment$5(ctx) {
	let div2;
	let div0;
	let div0_class_value;
	let t;
	let div1;
	let div1_class_value;
	let div2_class_value;
	let each_value = ensure_array_like(/*$_viewDates*/ ctx[2]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block$3(get_each_context$3(ctx, each_value, i));
	}

	return {
		c() {
			div2 = element("div");
			div0 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t = space();
			div1 = element("div");
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[0].days);
			attr(div0, "role", "row");
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[0].hiddenScroll);
			attr(div2, "class", div2_class_value = /*$theme*/ ctx[0].header);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div0, null);
				}
			}

			append(div2, t);
			append(div2, div1);
			/*div2_binding*/ ctx[10](div2);
		},
		p(ctx, [dirty]) {
			if (dirty & /*$theme, $_viewDates, $_dayTimes, $_intlDayHeader*/ 29) {
				each_value = ensure_array_like(/*$_viewDates*/ ctx[2]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context$3(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block$3(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div0, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty & /*$theme*/ 1 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[0].days)) {
				attr(div0, "class", div0_class_value);
			}

			if (dirty & /*$theme*/ 1 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[0].hiddenScroll)) {
				attr(div1, "class", div1_class_value);
			}

			if (dirty & /*$theme*/ 1 && div2_class_value !== (div2_class_value = /*$theme*/ ctx[0].header)) {
				attr(div2, "class", div2_class_value);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) {
				detach(div2);
			}

			destroy_each(each_blocks, detaching);
			/*div2_binding*/ ctx[10](null);
		}
	};
}

function instance$5($$self, $$props, $$invalidate) {
	let $theme;
	let $_headerEl;
	let $_viewDates;
	let $_intlDayHeader;
	let $_dayTimes;
	let { _headerEl, _intlDayHeader, _dayTimes, _viewDates, theme } = getContext('state');
	component_subscribe($$self, _headerEl, value => $$invalidate(1, $_headerEl = value));
	component_subscribe($$self, _intlDayHeader, value => $$invalidate(3, $_intlDayHeader = value));
	component_subscribe($$self, _dayTimes, value => $$invalidate(4, $_dayTimes = value));
	component_subscribe($$self, _viewDates, value => $$invalidate(2, $_viewDates = value));
	component_subscribe($$self, theme, value => $$invalidate(0, $theme = value));

	function div2_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			$_headerEl = $$value;
			_headerEl.set($_headerEl);
		});
	}

	return [
		$theme,
		$_headerEl,
		$_viewDates,
		$_intlDayHeader,
		$_dayTimes,
		_headerEl,
		_intlDayHeader,
		_dayTimes,
		_viewDates,
		theme,
		div2_binding
	];
}

class Header extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$5, create_fragment$5, safe_not_equal, {});
	}
}

function repositionEvent(chunk, dayChunks, longChunks, height) {
    chunk.top = 0;
    chunk.bottom = height;
    dayChunks.sort((a, b) => (a.top ?? 0) - (b.top ?? 0) || a.start - b.start || b.event.allDay - a.event.allDay);
    let margin = 1;
    for (let dayChunk of dayChunks) {
        if (dayChunk === chunk) {
            continue;
        }
        if (chunk.start < dayChunk.end && chunk.end > dayChunk.start && chunk.top < dayChunk.bottom && chunk.bottom > dayChunk.top) {
            let offset = dayChunk.bottom - chunk.top + 1;
            margin += offset;
            chunk.top += offset;
            chunk.bottom += offset;
        }
    }
    let key = chunk.date.getTime();
    if (longChunks[key]) {
        if (!longChunks[key].sorted) {
            longChunks[key].chunks.sort((a, b) => a.top - b.top);
            longChunks[key].sorted = true;
        }
        for (let longChunk of longChunks[key].chunks) {
            if (chunk.start < longChunk.end && chunk.end > longChunk.start && chunk.top < longChunk.bottom && chunk.bottom > longChunk.top) {
                let offset = longChunk.bottom - chunk.top + 1;
                margin += offset;
                chunk.top += offset;
                chunk.bottom += offset;
            }
        }
    }

    return margin;
}

/* packages/resource-timeline/src/Event.svelte generated by Svelte v4.2.16 */

function create_fragment$4(ctx) {
	let article;
	let div;
	let div_class_value;
	let setContent_action;
	let t;
	let switch_instance;
	let article_role_value;
	let article_tabindex_value;
	let current;
	let mounted;
	let dispose;
	var switch_value = /*$_interaction*/ ctx[10].resizer;

	function switch_props(ctx, dirty) {
		return { props: { event: /*event*/ ctx[0] } };
	}

	if (switch_value) {
		switch_instance = construct_svelte_component(switch_value, switch_props(ctx));

		switch_instance.$on("pointerdown", function () {
			if (is_function(/*createDragHandler*/ ctx[33](/*$_interaction*/ ctx[10], 'x'))) /*createDragHandler*/ ctx[33](/*$_interaction*/ ctx[10], 'x').apply(this, arguments);
		});
	}

	return {
		c() {
			article = element("article");
			div = element("div");
			t = space();
			if (switch_instance) create_component(switch_instance.$$.fragment);
			attr(div, "class", div_class_value = /*$theme*/ ctx[2].eventBody);
			attr(article, "class", /*classes*/ ctx[4]);
			attr(article, "style", /*style*/ ctx[5]);
			attr(article, "role", article_role_value = /*onclick*/ ctx[7] ? 'button' : undefined);
			attr(article, "tabindex", article_tabindex_value = /*onclick*/ ctx[7] ? 0 : undefined);
		},
		m(target, anchor) {
			insert(target, article, anchor);
			append(article, div);
			append(article, t);
			if (switch_instance) mount_component(switch_instance, article, null);
			/*article_binding*/ ctx[56](article);
			current = true;

			if (!mounted) {
				dispose = [
					action_destroyer(setContent_action = setContent.call(null, div, /*content*/ ctx[6])),
					listen(article, "click", function () {
						if (is_function(/*onclick*/ ctx[7])) /*onclick*/ ctx[7].apply(this, arguments);
					}),
					listen(article, "keydown", function () {
						if (is_function(/*onclick*/ ctx[7] && keyEnter(/*onclick*/ ctx[7]))) (/*onclick*/ ctx[7] && keyEnter(/*onclick*/ ctx[7])).apply(this, arguments);
					}),
					listen(article, "mouseenter", function () {
						if (is_function(/*createHandler*/ ctx[32](/*$eventMouseEnter*/ ctx[8], /*display*/ ctx[1]))) /*createHandler*/ ctx[32](/*$eventMouseEnter*/ ctx[8], /*display*/ ctx[1]).apply(this, arguments);
					}),
					listen(article, "mouseleave", function () {
						if (is_function(/*createHandler*/ ctx[32](/*$eventMouseLeave*/ ctx[9], /*display*/ ctx[1]))) /*createHandler*/ ctx[32](/*$eventMouseLeave*/ ctx[9], /*display*/ ctx[1]).apply(this, arguments);
					}),
					listen(article, "pointerdown", function () {
						if (is_function(!bgEvent(/*display*/ ctx[1]) && !helperEvent(/*display*/ ctx[1]) && /*createDragHandler*/ ctx[33](/*$_interaction*/ ctx[10]))) (!bgEvent(/*display*/ ctx[1]) && !helperEvent(/*display*/ ctx[1]) && /*createDragHandler*/ ctx[33](/*$_interaction*/ ctx[10])).apply(this, arguments);
					})
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (!current || dirty[0] & /*$theme*/ 4 && div_class_value !== (div_class_value = /*$theme*/ ctx[2].eventBody)) {
				attr(div, "class", div_class_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty[0] & /*content*/ 64) setContent_action.update.call(null, /*content*/ ctx[6]);

			if (dirty[0] & /*$_interaction*/ 1024 && switch_value !== (switch_value = /*$_interaction*/ ctx[10].resizer)) {
				if (switch_instance) {
					group_outros();
					const old_component = switch_instance;

					transition_out(old_component.$$.fragment, 1, 0, () => {
						destroy_component(old_component, 1);
					});

					check_outros();
				}

				if (switch_value) {
					switch_instance = construct_svelte_component(switch_value, switch_props(ctx));

					switch_instance.$on("pointerdown", function () {
						if (is_function(/*createDragHandler*/ ctx[33](/*$_interaction*/ ctx[10], 'x'))) /*createDragHandler*/ ctx[33](/*$_interaction*/ ctx[10], 'x').apply(this, arguments);
					});

					create_component(switch_instance.$$.fragment);
					transition_in(switch_instance.$$.fragment, 1);
					mount_component(switch_instance, article, null);
				} else {
					switch_instance = null;
				}
			} else if (switch_value) {
				const switch_instance_changes = {};
				if (dirty[0] & /*event*/ 1) switch_instance_changes.event = /*event*/ ctx[0];
				switch_instance.$set(switch_instance_changes);
			}

			if (!current || dirty[0] & /*classes*/ 16) {
				attr(article, "class", /*classes*/ ctx[4]);
			}

			if (!current || dirty[0] & /*style*/ 32) {
				attr(article, "style", /*style*/ ctx[5]);
			}

			if (!current || dirty[0] & /*onclick*/ 128 && article_role_value !== (article_role_value = /*onclick*/ ctx[7] ? 'button' : undefined)) {
				attr(article, "role", article_role_value);
			}

			if (!current || dirty[0] & /*onclick*/ 128 && article_tabindex_value !== (article_tabindex_value = /*onclick*/ ctx[7] ? 0 : undefined)) {
				attr(article, "tabindex", article_tabindex_value);
			}
		},
		i(local) {
			if (current) return;
			if (switch_instance) transition_in(switch_instance.$$.fragment, local);
			current = true;
		},
		o(local) {
			if (switch_instance) transition_out(switch_instance.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(article);
			}

			if (switch_instance) destroy_component(switch_instance);
			/*article_binding*/ ctx[56](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$4($$self, $$props, $$invalidate) {
	let $eventClick;
	let $_view;
	let $eventAllUpdated;
	let $eventDidMount;
	let $_intlEventTime;
	let $theme;
	let $eventContent;
	let $displayEventEnd;
	let $eventClassNames;
	let $_iClasses;
	let $eventTextColor;
	let $_resTxtColor;
	let $eventColor;
	let $eventBackgroundColor;
	let $_resBgColor;
	let $slotWidth;
	let $_dayTimeLimits;
	let $slotDuration;
	let $eventMouseEnter;
	let $eventMouseLeave;
	let $_interaction;
	let { date } = $$props;
	let { chunk } = $$props;
	let { dayChunks = [] } = $$props;
	let { longChunks = {} } = $$props;
	let { resource = undefined } = $$props;
	let { displayEventEnd, eventAllUpdated, eventBackgroundColor, eventTextColor, eventColor, eventContent, eventClick, eventDidMount, eventClassNames, eventMouseEnter, eventMouseLeave, slotDuration, slotWidth, theme, _view, _intlEventTime, _interaction, _iClasses, _resBgColor, _resTxtColor, _dayTimeLimits, _tasks } = getContext('state');
	component_subscribe($$self, displayEventEnd, value => $$invalidate(45, $displayEventEnd = value));
	component_subscribe($$self, eventAllUpdated, value => $$invalidate(58, $eventAllUpdated = value));
	component_subscribe($$self, eventBackgroundColor, value => $$invalidate(51, $eventBackgroundColor = value));
	component_subscribe($$self, eventTextColor, value => $$invalidate(48, $eventTextColor = value));
	component_subscribe($$self, eventColor, value => $$invalidate(50, $eventColor = value));
	component_subscribe($$self, eventContent, value => $$invalidate(44, $eventContent = value));
	component_subscribe($$self, eventClick, value => $$invalidate(41, $eventClick = value));
	component_subscribe($$self, eventDidMount, value => $$invalidate(59, $eventDidMount = value));
	component_subscribe($$self, eventClassNames, value => $$invalidate(46, $eventClassNames = value));
	component_subscribe($$self, eventMouseEnter, value => $$invalidate(8, $eventMouseEnter = value));
	component_subscribe($$self, eventMouseLeave, value => $$invalidate(9, $eventMouseLeave = value));
	component_subscribe($$self, slotDuration, value => $$invalidate(55, $slotDuration = value));
	component_subscribe($$self, slotWidth, value => $$invalidate(53, $slotWidth = value));
	component_subscribe($$self, theme, value => $$invalidate(2, $theme = value));
	component_subscribe($$self, _view, value => $$invalidate(42, $_view = value));
	component_subscribe($$self, _intlEventTime, value => $$invalidate(43, $_intlEventTime = value));
	component_subscribe($$self, _interaction, value => $$invalidate(10, $_interaction = value));
	component_subscribe($$self, _iClasses, value => $$invalidate(47, $_iClasses = value));
	component_subscribe($$self, _resBgColor, value => $$invalidate(52, $_resBgColor = value));
	component_subscribe($$self, _resTxtColor, value => $$invalidate(49, $_resTxtColor = value));
	component_subscribe($$self, _dayTimeLimits, value => $$invalidate(54, $_dayTimeLimits = value));
	let el;
	let event;
	let display;
	let classes;
	let style;
	let content;
	let timeText;
	let onclick;
	let margin = helperEvent(chunk.event.display) ? 1 : 0;

	onMount(() => {
		if (is_function($eventDidMount)) {
			$eventDidMount({
				event: toEventWithLocalDates(event),
				timeText,
				el,
				view: toViewWithLocalDates($_view)
			});
		}
	});

	afterUpdate(() => {
		if (is_function($eventAllUpdated) && !helperEvent(display)) {
			task(() => $eventAllUpdated({ view: toViewWithLocalDates($_view) }), 'eau', _tasks);
		}
	});

	function createHandler(fn, display) {
		return !helperEvent(display) && is_function(fn)
		? jsEvent => fn({
				event: toEventWithLocalDates(event),
				el,
				jsEvent,
				view: toViewWithLocalDates($_view)
			})
		: undefined;
	}

	function createDragHandler(interaction, resize) {
		return interaction.action
		? jsEvent => interaction.action.drag(event, jsEvent, resize, null, [margin, resource])
		: undefined;
	}

	function reposition() {
		if (!el) {
			return 0;
		}

		let h = height(el);
		$$invalidate(40, margin = repositionEvent(chunk, dayChunks, longChunks, h));
		return margin + h;
	}

	function article_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(3, el);
		});
	}

	$$self.$$set = $$props => {
		if ('date' in $$props) $$invalidate(34, date = $$props.date);
		if ('chunk' in $$props) $$invalidate(35, chunk = $$props.chunk);
		if ('dayChunks' in $$props) $$invalidate(36, dayChunks = $$props.dayChunks);
		if ('longChunks' in $$props) $$invalidate(37, longChunks = $$props.longChunks);
		if ('resource' in $$props) $$invalidate(38, resource = $$props.resource);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[1] & /*chunk*/ 16) {
			$$invalidate(0, event = chunk.event);
		}

		if ($$self.$$.dirty[0] & /*event, style, display, $theme*/ 39 | $$self.$$.dirty[1] & /*$slotDuration, chunk, date, $_dayTimeLimits, $slotWidth, $_resBgColor, $eventBackgroundColor, $eventColor, $_resTxtColor, $eventTextColor, margin, resource, $_iClasses, $eventClassNames, $_view*/ 33524376) {
			{
				$$invalidate(1, display = event.display);

				// Style
				let step = toSeconds($slotDuration);

				let start = (chunk.start - date) / 1000;
				let end = (chunk.end - date) / 1000;

				// Shift start
				start -= toSeconds($_dayTimeLimits[date.getTime()]?.min);

				if (start < 0) {
					start = 0;
				}

				// Shift end
				let cut = 0;

				for (let i = 0; i < chunk.days; ++i) {
					let slotTimeLimits = $_dayTimeLimits[chunk.dates[i].getTime()];
					let offsetStart = toSeconds(slotTimeLimits?.min);
					let offsetEnd = toSeconds(slotTimeLimits?.max, DAY_IN_SECONDS);
					let dayStart = DAY_IN_SECONDS * i;

					// Cut offsetEnd
					let dayEnd = dayStart + DAY_IN_SECONDS;

					if (dayEnd > end) {
						dayEnd = end;
					}

					if (dayEnd > dayStart + offsetEnd) {
						cut += dayEnd - dayStart - offsetEnd;
					}

					// Cut offsetStart
					let c = end - dayStart;

					if (c > offsetStart) {
						c = offsetStart;
					}

					cut += c;
				}

				end -= cut;
				let left = start / step * $slotWidth;
				let width = (end - start) / step * $slotWidth;
				let bgColor = event.backgroundColor || $_resBgColor(event) || $eventBackgroundColor || $eventColor;
				let txtColor = event.textColor || $_resTxtColor(event) || $eventTextColor;
				let marginTop = margin;

				if (event._margin) {
					// Force margin for helper events
					let [_margin, _resource] = event._margin;

					if (resource === _resource) {
						marginTop = _margin;
					}
				}

				$$invalidate(5, style = `left:${left}px;` + `width:${width}px;` + `margin-top:${marginTop}px;`);

				if (bgColor) {
					$$invalidate(5, style += `background-color:${bgColor};`);
				}

				if (txtColor) {
					$$invalidate(5, style += `color:${txtColor};`);
				}

				// Class
				$$invalidate(4, classes = [
					bgEvent(display) ? $theme.bgEvent : $theme.event,
					...$_iClasses([], event),
					...createEventClasses($eventClassNames, event, $_view)
				].join(' '));
			}
		}

		if ($$self.$$.dirty[0] & /*$theme*/ 4 | $$self.$$.dirty[1] & /*chunk, $displayEventEnd, $eventContent, $_intlEventTime, $_view*/ 30736) {
			// Content
			$$invalidate(6, [timeText, content] = createEventContent(chunk, $displayEventEnd, $eventContent, $theme, $_intlEventTime, $_view), content);
		}

		if ($$self.$$.dirty[0] & /*display*/ 2 | $$self.$$.dirty[1] & /*$eventClick*/ 1024) {
			// Onclick handler
			$$invalidate(7, onclick = !bgEvent(display) && createHandler($eventClick, display));
		}
	};

	return [
		event,
		display,
		$theme,
		el,
		classes,
		style,
		content,
		onclick,
		$eventMouseEnter,
		$eventMouseLeave,
		$_interaction,
		displayEventEnd,
		eventAllUpdated,
		eventBackgroundColor,
		eventTextColor,
		eventColor,
		eventContent,
		eventClick,
		eventDidMount,
		eventClassNames,
		eventMouseEnter,
		eventMouseLeave,
		slotDuration,
		slotWidth,
		theme,
		_view,
		_intlEventTime,
		_interaction,
		_iClasses,
		_resBgColor,
		_resTxtColor,
		_dayTimeLimits,
		createHandler,
		createDragHandler,
		date,
		chunk,
		dayChunks,
		longChunks,
		resource,
		reposition,
		margin,
		$eventClick,
		$_view,
		$_intlEventTime,
		$eventContent,
		$displayEventEnd,
		$eventClassNames,
		$_iClasses,
		$eventTextColor,
		$_resTxtColor,
		$eventColor,
		$eventBackgroundColor,
		$_resBgColor,
		$slotWidth,
		$_dayTimeLimits,
		$slotDuration,
		article_binding
	];
}

class Event extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance$4,
			create_fragment$4,
			safe_not_equal,
			{
				date: 34,
				chunk: 35,
				dayChunks: 36,
				longChunks: 37,
				resource: 38,
				reposition: 39
			},
			null,
			[-1, -1]
		);
	}

	get reposition() {
		return this.$$.ctx[39];
	}
}

/* packages/resource-timeline/src/Day.svelte generated by Svelte v4.2.16 */

function get_each_context$2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[32] = list[i];
	child_ctx[33] = list;
	child_ctx[34] = i;
	return child_ctx;
}

function get_each_context_1$1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[32] = list[i];
	return child_ctx;
}

// (87:8) {#each bgChunks as chunk (chunk.event)}
function create_each_block_1$1(key_1, ctx) {
	let first;
	let event;
	let current;

	event = new Event({
			props: {
				date: /*date*/ ctx[0],
				chunk: /*chunk*/ ctx[32]
			}
		});

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			create_component(event.$$.fragment);
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			mount_component(event, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const event_changes = {};
			if (dirty[0] & /*date*/ 1) event_changes.date = /*date*/ ctx[0];
			if (dirty[0] & /*bgChunks*/ 64) event_changes.chunk = /*chunk*/ ctx[32];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(first);
			}

			destroy_component(event, detaching);
		}
	};
}

// (91:8) {#if iChunks[1] && datesEqual(iChunks[1].date, date)}
function create_if_block_1(ctx) {
	let event;
	let current;

	event = new Event({
			props: {
				date: /*date*/ ctx[0],
				chunk: /*iChunks*/ ctx[3][1]
			}
		});

	return {
		c() {
			create_component(event.$$.fragment);
		},
		m(target, anchor) {
			mount_component(event, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const event_changes = {};
			if (dirty[0] & /*date*/ 1) event_changes.date = /*date*/ ctx[0];
			if (dirty[0] & /*iChunks*/ 8) event_changes.chunk = /*iChunks*/ ctx[3][1];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(event, detaching);
		}
	};
}

// (94:8) {#each dayChunks as chunk, i (chunk.event)}
function create_each_block$2(key_1, ctx) {
	let first;
	let event;
	let i = /*i*/ ctx[34];
	let current;
	const assign_event = () => /*event_binding*/ ctx[28](event, i);
	const unassign_event = () => /*event_binding*/ ctx[28](null, i);

	let event_props = {
		date: /*date*/ ctx[0],
		chunk: /*chunk*/ ctx[32],
		dayChunks: /*dayChunks*/ ctx[5],
		longChunks: /*longChunks*/ ctx[2],
		resource: /*resource*/ ctx[1]
	};

	event = new Event({ props: event_props });
	assign_event();

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			create_component(event.$$.fragment);
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			mount_component(event, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (i !== /*i*/ ctx[34]) {
				unassign_event();
				i = /*i*/ ctx[34];
				assign_event();
			}

			const event_changes = {};
			if (dirty[0] & /*date*/ 1) event_changes.date = /*date*/ ctx[0];
			if (dirty[0] & /*dayChunks*/ 32) event_changes.chunk = /*chunk*/ ctx[32];
			if (dirty[0] & /*dayChunks*/ 32) event_changes.dayChunks = /*dayChunks*/ ctx[5];
			if (dirty[0] & /*longChunks*/ 4) event_changes.longChunks = /*longChunks*/ ctx[2];
			if (dirty[0] & /*resource*/ 2) event_changes.resource = /*resource*/ ctx[1];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(first);
			}

			unassign_event();
			destroy_component(event, detaching);
		}
	};
}

// (98:8) {#if iChunks[0] && datesEqual(iChunks[0].date, date)}
function create_if_block(ctx) {
	let event;
	let current;

	event = new Event({
			props: {
				date: /*date*/ ctx[0],
				chunk: /*iChunks*/ ctx[3][0],
				resource: /*resource*/ ctx[1]
			}
		});

	return {
		c() {
			create_component(event.$$.fragment);
		},
		m(target, anchor) {
			mount_component(event, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const event_changes = {};
			if (dirty[0] & /*date*/ 1) event_changes.date = /*date*/ ctx[0];
			if (dirty[0] & /*iChunks*/ 8) event_changes.chunk = /*iChunks*/ ctx[3][0];
			if (dirty[0] & /*resource*/ 2) event_changes.resource = /*resource*/ ctx[1];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(event, detaching);
		}
	};
}

function create_fragment$3(ctx) {
	let div1;
	let div0;
	let each_blocks_1 = [];
	let each0_lookup = new Map();
	let t0;
	let show_if_1 = /*iChunks*/ ctx[3][1] && datesEqual(/*iChunks*/ ctx[3][1].date, /*date*/ ctx[0]);
	let t1;
	let each_blocks = [];
	let each1_lookup = new Map();
	let t2;
	let show_if = /*iChunks*/ ctx[3][0] && datesEqual(/*iChunks*/ ctx[3][0].date, /*date*/ ctx[0]);
	let div0_class_value;
	let div1_class_value;
	let current;
	let mounted;
	let dispose;
	let each_value_1 = ensure_array_like(/*bgChunks*/ ctx[6]);
	const get_key = ctx => /*chunk*/ ctx[32].event;

	for (let i = 0; i < each_value_1.length; i += 1) {
		let child_ctx = get_each_context_1$1(ctx, each_value_1, i);
		let key = get_key(child_ctx);
		each0_lookup.set(key, each_blocks_1[i] = create_each_block_1$1(key, child_ctx));
	}

	let if_block0 = show_if_1 && create_if_block_1(ctx);
	let each_value = ensure_array_like(/*dayChunks*/ ctx[5]);
	const get_key_1 = ctx => /*chunk*/ ctx[32].event;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context$2(ctx, each_value, i);
		let key = get_key_1(child_ctx);
		each1_lookup.set(key, each_blocks[i] = create_each_block$2(key, child_ctx));
	}

	let if_block1 = show_if && create_if_block(ctx);

	return {
		c() {
			div1 = element("div");
			div0 = element("div");

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			t0 = space();
			if (if_block0) if_block0.c();
			t1 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t2 = space();
			if (if_block1) if_block1.c();
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[12].events);

			attr(div1, "class", div1_class_value = "" + (/*$theme*/ ctx[12].day + " " + /*$theme*/ ctx[12].weekdays?.[/*date*/ ctx[0].getUTCDay()] + (/*isToday*/ ctx[8] ? ' ' + /*$theme*/ ctx[12].today : '') + (/*highlight*/ ctx[9]
			? ' ' + /*$theme*/ ctx[12].highlight
			: '')));

			set_style(div1, "flex-grow", ceil((toSeconds(/*slotTimeLimits*/ ctx[7].max) - toSeconds(/*slotTimeLimits*/ ctx[7].min)) / toSeconds(/*$slotDuration*/ ctx[11])));
			attr(div1, "role", "cell");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				if (each_blocks_1[i]) {
					each_blocks_1[i].m(div0, null);
				}
			}

			append(div0, t0);
			if (if_block0) if_block0.m(div0, null);
			append(div0, t1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div0, null);
				}
			}

			append(div0, t2);
			if (if_block1) if_block1.m(div0, null);
			/*div1_binding*/ ctx[29](div1);
			current = true;

			if (!mounted) {
				dispose = [
					listen(div1, "pointerleave", function () {
						if (is_function(/*$_interaction*/ ctx[13].pointer?.leave)) /*$_interaction*/ ctx[13].pointer?.leave.apply(this, arguments);
					}),
					listen(div1, "pointerdown", function () {
						if (is_function(/*$_interaction*/ ctx[13].action?.select)) /*$_interaction*/ ctx[13].action?.select.apply(this, arguments);
					})
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty[0] & /*date, bgChunks*/ 65) {
				each_value_1 = ensure_array_like(/*bgChunks*/ ctx[6]);
				group_outros();
				each_blocks_1 = update_keyed_each(each_blocks_1, dirty, get_key, 1, ctx, each_value_1, each0_lookup, div0, outro_and_destroy_block, create_each_block_1$1, t0, get_each_context_1$1);
				check_outros();
			}

			if (dirty[0] & /*iChunks, date*/ 9) show_if_1 = /*iChunks*/ ctx[3][1] && datesEqual(/*iChunks*/ ctx[3][1].date, /*date*/ ctx[0]);

			if (show_if_1) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty[0] & /*iChunks, date*/ 9) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_1(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div0, t1);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (dirty[0] & /*date, dayChunks, longChunks, resource, refs*/ 1063) {
				each_value = ensure_array_like(/*dayChunks*/ ctx[5]);
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key_1, 1, ctx, each_value, each1_lookup, div0, outro_and_destroy_block, create_each_block$2, t2, get_each_context$2);
				check_outros();
			}

			if (dirty[0] & /*iChunks, date*/ 9) show_if = /*iChunks*/ ctx[3][0] && datesEqual(/*iChunks*/ ctx[3][0].date, /*date*/ ctx[0]);

			if (show_if) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty[0] & /*iChunks, date*/ 9) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div0, null);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (!current || dirty[0] & /*$theme*/ 4096 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[12].events)) {
				attr(div0, "class", div0_class_value);
			}

			if (!current || dirty[0] & /*$theme, date, isToday, highlight*/ 4865 && div1_class_value !== (div1_class_value = "" + (/*$theme*/ ctx[12].day + " " + /*$theme*/ ctx[12].weekdays?.[/*date*/ ctx[0].getUTCDay()] + (/*isToday*/ ctx[8] ? ' ' + /*$theme*/ ctx[12].today : '') + (/*highlight*/ ctx[9]
			? ' ' + /*$theme*/ ctx[12].highlight
			: '')))) {
				attr(div1, "class", div1_class_value);
			}

			if (!current || dirty[0] & /*slotTimeLimits, $slotDuration*/ 2176) {
				set_style(div1, "flex-grow", ceil((toSeconds(/*slotTimeLimits*/ ctx[7].max) - toSeconds(/*slotTimeLimits*/ ctx[7].min)) / toSeconds(/*$slotDuration*/ ctx[11])));
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_1.length; i += 1) {
				transition_in(each_blocks_1[i]);
			}

			transition_in(if_block0);

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			transition_in(if_block1);
			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks_1.length; i += 1) {
				transition_out(each_blocks_1[i]);
			}

			transition_out(if_block0);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			transition_out(if_block1);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div1);
			}

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].d();
			}

			if (if_block0) if_block0.d();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}

			if (if_block1) if_block1.d();
			/*div1_binding*/ ctx[29](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$3($$self, $$props, $$invalidate) {
	let $slotWidth;
	let $slotDuration;
	let $highlightedDates;
	let $_today;
	let $_dayTimeLimits;
	let $theme;
	let $_interaction;
	let { date } = $$props;
	let { resource } = $$props;
	let { chunks } = $$props;
	let { longChunks } = $$props;
	let { iChunks = [] } = $$props;
	let { highlightedDates, slotDuration, slotWidth, theme, _interaction, _today, _dayTimeLimits } = getContext('state');
	component_subscribe($$self, highlightedDates, value => $$invalidate(25, $highlightedDates = value));
	component_subscribe($$self, slotDuration, value => $$invalidate(11, $slotDuration = value));
	component_subscribe($$self, slotWidth, value => $$invalidate(30, $slotWidth = value));
	component_subscribe($$self, theme, value => $$invalidate(12, $theme = value));
	component_subscribe($$self, _interaction, value => $$invalidate(13, $_interaction = value));
	component_subscribe($$self, _today, value => $$invalidate(26, $_today = value));
	component_subscribe($$self, _dayTimeLimits, value => $$invalidate(27, $_dayTimeLimits = value));
	let el;
	let dayChunks, bgChunks;
	let isToday, highlight;
	let refs = [];
	let slotTimeLimits;
	let start, end;

	function dateFromPoint(x, y) {
		x -= rect(el).left;

		return {
			allDay: false,
			date: addDuration(addDuration(cloneDate(date), slotTimeLimits.min), $slotDuration, floor(x / $slotWidth)),
			resource,
			dayEl: el
		};
	}

	function reposition() {
		return max(...runReposition(refs, dayChunks));
	}

	function event_binding($$value, i) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			refs[i] = $$value;
			$$invalidate(10, refs);
		});
	}

	function div1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(4, el);
		});
	}

	$$self.$$set = $$props => {
		if ('date' in $$props) $$invalidate(0, date = $$props.date);
		if ('resource' in $$props) $$invalidate(1, resource = $$props.resource);
		if ('chunks' in $$props) $$invalidate(21, chunks = $$props.chunks);
		if ('longChunks' in $$props) $$invalidate(2, longChunks = $$props.longChunks);
		if ('iChunks' in $$props) $$invalidate(3, iChunks = $$props.iChunks);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*$_dayTimeLimits, date, slotTimeLimits*/ 134217857) {
			{
				$$invalidate(7, slotTimeLimits = $_dayTimeLimits[date.getTime()]);
				$$invalidate(23, start = addDuration(cloneDate(date), slotTimeLimits.min));
				$$invalidate(24, end = addDuration(cloneDate(date), slotTimeLimits.max));
			}
		}

		if ($$self.$$.dirty[0] & /*chunks, date, end, start, bgChunks, dayChunks*/ 27263073) {
			{
				$$invalidate(5, dayChunks = []);
				$$invalidate(6, bgChunks = []);

				for (let chunk of chunks) {
					if (datesEqual(chunk.date, date) && (chunk.start < end && chunk.end > start || chunk.event.allDay)) {
						switch (chunk.event.display) {
							case 'background':
								bgChunks.push(chunk);
								break;
							default:
								dayChunks.push(chunk);
						}
					}
				}
			}
		}

		if ($$self.$$.dirty[0] & /*date, $_today*/ 67108865) {
			$$invalidate(8, isToday = datesEqual(date, $_today));
		}

		if ($$self.$$.dirty[0] & /*$highlightedDates, date*/ 33554433) {
			$$invalidate(9, highlight = $highlightedDates.some(d => datesEqual(d, date)));
		}

		if ($$self.$$.dirty[0] & /*el*/ 16) {
			if (el) {
				setPayload(el, dateFromPoint);
			}
		}
	};

	return [
		date,
		resource,
		longChunks,
		iChunks,
		el,
		dayChunks,
		bgChunks,
		slotTimeLimits,
		isToday,
		highlight,
		refs,
		$slotDuration,
		$theme,
		$_interaction,
		highlightedDates,
		slotDuration,
		slotWidth,
		theme,
		_interaction,
		_today,
		_dayTimeLimits,
		chunks,
		reposition,
		start,
		end,
		$highlightedDates,
		$_today,
		$_dayTimeLimits,
		event_binding,
		div1_binding
	];
}

class Day extends SvelteComponent {
	constructor(options) {
		super();

		init(
			this,
			options,
			instance$3,
			create_fragment$3,
			safe_not_equal,
			{
				date: 0,
				resource: 1,
				chunks: 21,
				longChunks: 2,
				iChunks: 3,
				reposition: 22
			},
			null,
			[-1, -1]
		);
	}

	get reposition() {
		return this.$$.ctx[22];
	}
}

/* packages/resource-timeline/src/Days.svelte generated by Svelte v4.2.16 */

function get_each_context$1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[24] = list[i];
	child_ctx[25] = list;
	child_ctx[26] = i;
	return child_ctx;
}

// (58:4) {#each $_viewDates as date, i}
function create_each_block$1(ctx) {
	let day;
	let i = /*i*/ ctx[26];
	let current;
	const assign_day = () => /*day_binding*/ ctx[20](day, i);
	const unassign_day = () => /*day_binding*/ ctx[20](null, i);

	let day_props = {
		date: /*date*/ ctx[24],
		resource: /*resource*/ ctx[0],
		chunks: /*chunks*/ ctx[1],
		longChunks: /*longChunks*/ ctx[3],
		iChunks: /*iChunks*/ ctx[4]
	};

	day = new Day({ props: day_props });
	assign_day();

	return {
		c() {
			create_component(day.$$.fragment);
		},
		m(target, anchor) {
			mount_component(day, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (i !== /*i*/ ctx[26]) {
				unassign_day();
				i = /*i*/ ctx[26];
				assign_day();
			}

			const day_changes = {};
			if (dirty & /*$_viewDates*/ 4) day_changes.date = /*date*/ ctx[24];
			if (dirty & /*resource*/ 1) day_changes.resource = /*resource*/ ctx[0];
			if (dirty & /*chunks*/ 2) day_changes.chunks = /*chunks*/ ctx[1];
			if (dirty & /*longChunks*/ 8) day_changes.longChunks = /*longChunks*/ ctx[3];
			if (dirty & /*iChunks*/ 16) day_changes.iChunks = /*iChunks*/ ctx[4];
			day.$set(day_changes);
		},
		i(local) {
			if (current) return;
			transition_in(day.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(day.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			unassign_day();
			destroy_component(day, detaching);
		}
	};
}

function create_fragment$2(ctx) {
	let div;
	let div_class_value;
	let current;
	let mounted;
	let dispose;
	let each_value = ensure_array_like(/*$_viewDates*/ ctx[2]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block$1(get_each_context$1(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", div_class_value = /*$theme*/ ctx[7].days);
			set_style(div, "flex-basis", max(/*height*/ ctx[6], 34) + "px");
			attr(div, "role", "row");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div, null);
				}
			}

			current = true;

			if (!mounted) {
				dispose = listen(window, "resize", /*reposition*/ ctx[14]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*$_viewDates, resource, chunks, longChunks, iChunks, refs*/ 63) {
				each_value = ensure_array_like(/*$_viewDates*/ ctx[2]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context$1(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block$1(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (!current || dirty & /*$theme*/ 128 && div_class_value !== (div_class_value = /*$theme*/ ctx[7].days)) {
				attr(div, "class", div_class_value);
			}

			if (!current || dirty & /*height*/ 64) {
				set_style(div, "flex-basis", max(/*height*/ ctx[6], 34) + "px");
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			destroy_each(each_blocks, detaching);
			mounted = false;
			dispose();
		}
	};
}

function instance$2($$self, $$props, $$invalidate) {
	let $hiddenDays;
	let $_iEvents;
	let $_events;
	let $_resHs;
	let $_viewDates;
	let $theme;
	let { resource } = $$props;
	let { _viewDates, _events, _iEvents, _queue2, _resHs, hiddenDays, theme } = getContext('state');
	component_subscribe($$self, _viewDates, value => $$invalidate(2, $_viewDates = value));
	component_subscribe($$self, _events, value => $$invalidate(19, $_events = value));
	component_subscribe($$self, _iEvents, value => $$invalidate(18, $_iEvents = value));
	component_subscribe($$self, _resHs, value => $$invalidate(21, $_resHs = value));
	component_subscribe($$self, hiddenDays, value => $$invalidate(17, $hiddenDays = value));
	component_subscribe($$self, theme, value => $$invalidate(7, $theme = value));
	let chunks, longChunks, iChunks = [];
	let start;
	let end;
	let refs = [];
	let height = 0;
	let debounceHandle = {};

	function reposition() {
		debounce(
			() => {
				$$invalidate(6, height = ceil(max(...runReposition(refs, $_viewDates))) + 10);
				$_resHs.set(resource, height);
				_resHs.set($_resHs);
			},
			debounceHandle,
			_queue2
		);
	}

	function day_binding($$value, i) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			refs[i] = $$value;
			$$invalidate(5, refs);
		});
	}

	$$self.$$set = $$props => {
		if ('resource' in $$props) $$invalidate(0, resource = $$props.resource);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$_viewDates*/ 4) {
			{
				$$invalidate(15, start = $_viewDates[0]);
				$$invalidate(16, end = addDay(cloneDate($_viewDates[$_viewDates.length - 1])));
			}
		}

		if ($$self.$$.dirty & /*$_events, start, end, resource, chunks, $hiddenDays*/ 753667) {
			{
				$$invalidate(1, chunks = []);

				for (let event of $_events) {
					if (eventIntersects(event, start, end, resource)) {
						let chunk = createEventChunk(event, start, end);
						chunks.push(chunk);
					}
				}

				$$invalidate(3, longChunks = prepareEventChunks(chunks, $hiddenDays));

				// Run reposition only when events get changed
				reposition();
			}
		}

		if ($$self.$$.dirty & /*$_iEvents, start, end, resource, $hiddenDays*/ 491521) {
			$$invalidate(4, iChunks = $_iEvents.map(event => {
				let chunk;

				if (event && eventIntersects(event, start, end, resource)) {
					chunk = createEventChunk(event, start, end);
					prepareEventChunks([chunk], $hiddenDays);
				} else {
					chunk = null;
				}

				return chunk;
			}));
		}
	};

	return [
		resource,
		chunks,
		$_viewDates,
		longChunks,
		iChunks,
		refs,
		height,
		$theme,
		_viewDates,
		_events,
		_iEvents,
		_resHs,
		hiddenDays,
		theme,
		reposition,
		start,
		end,
		$hiddenDays,
		$_iEvents,
		$_events,
		day_binding
	];
}

class Days extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$2, create_fragment$2, safe_not_equal, { resource: 0 });
	}
}

/* packages/resource-timeline/src/Body.svelte generated by Svelte v4.2.16 */

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[26] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[29] = list[i];
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[32] = list[i];
	return child_ctx;
}

// (40:16) {#each $_dayTimes[date.getTime()] as time}
function create_each_block_2(ctx) {
	let div;
	let div_class_value;

	return {
		c() {
			div = element("div");
			attr(div, "class", div_class_value = /*$theme*/ ctx[2].line);
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$theme*/ 4 && div_class_value !== (div_class_value = /*$theme*/ ctx[2].line)) {
				attr(div, "class", div_class_value);
			}
		},
		d(detaching) {
			if (detaching) {
				detach(div);
			}
		}
	};
}

// (39:12) {#each $_viewDates as date}
function create_each_block_1(ctx) {
	let each_1_anchor;
	let each_value_2 = ensure_array_like(/*$_dayTimes*/ ctx[3][/*date*/ ctx[29].getTime()]);
	let each_blocks = [];

	for (let i = 0; i < each_value_2.length; i += 1) {
		each_blocks[i] = create_each_block_2(get_each_context_2(ctx, each_value_2, i));
	}

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert(target, each_1_anchor, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$theme, $_dayTimes, $_viewDates*/ 14) {
				each_value_2 = ensure_array_like(/*$_dayTimes*/ ctx[3][/*date*/ ctx[29].getTime()]);
				let i;

				for (i = 0; i < each_value_2.length; i += 1) {
					const child_ctx = get_each_context_2(ctx, each_value_2, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_2(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_2.length;
			}
		},
		d(detaching) {
			if (detaching) {
				detach(each_1_anchor);
			}

			destroy_each(each_blocks, detaching);
		}
	};
}

// (45:8) {#each $_viewResources as resource}
function create_each_block(ctx) {
	let days;
	let current;

	days = new Days({
			props: { resource: /*resource*/ ctx[26] }
		});

	return {
		c() {
			create_component(days.$$.fragment);
		},
		m(target, anchor) {
			mount_component(days, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const days_changes = {};
			if (dirty[0] & /*$_viewResources*/ 16) days_changes.resource = /*resource*/ ctx[26];
			days.$set(days_changes);
		},
		i(local) {
			if (current) return;
			transition_in(days.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(days.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(days, detaching);
		}
	};
}

function create_fragment$1(ctx) {
	let div2;
	let div1;
	let div0;
	let div0_class_value;
	let t;
	let div1_class_value;
	let div2_class_value;
	let current;
	let mounted;
	let dispose;
	let each_value_1 = ensure_array_like(/*$_viewDates*/ ctx[1]);
	let each_blocks_1 = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks_1[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	let each_value = ensure_array_like(/*$_viewResources*/ ctx[4]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div2 = element("div");
			div1 = element("div");
			div0 = element("div");

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			t = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div0, "class", div0_class_value = /*$theme*/ ctx[2].lines);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[2].content);
			attr(div2, "class", div2_class_value = /*$theme*/ ctx[2].body);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div1);
			append(div1, div0);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				if (each_blocks_1[i]) {
					each_blocks_1[i].m(div0, null);
				}
			}

			append(div1, t);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(div1, null);
				}
			}

			/*div2_binding*/ ctx[18](div2);
			current = true;

			if (!mounted) {
				dispose = listen(div2, "scroll", /*handleScroll*/ ctx[16]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$_dayTimes, $_viewDates, $theme*/ 14) {
				each_value_1 = ensure_array_like(/*$_viewDates*/ ctx[1]);
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks_1[i]) {
						each_blocks_1[i].p(child_ctx, dirty);
					} else {
						each_blocks_1[i] = create_each_block_1(child_ctx);
						each_blocks_1[i].c();
						each_blocks_1[i].m(div0, null);
					}
				}

				for (; i < each_blocks_1.length; i += 1) {
					each_blocks_1[i].d(1);
				}

				each_blocks_1.length = each_value_1.length;
			}

			if (!current || dirty[0] & /*$theme*/ 4 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[2].lines)) {
				attr(div0, "class", div0_class_value);
			}

			if (dirty[0] & /*$_viewResources*/ 16) {
				each_value = ensure_array_like(/*$_viewResources*/ ctx[4]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div1, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (!current || dirty[0] & /*$theme*/ 4 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[2].content)) {
				attr(div1, "class", div1_class_value);
			}

			if (!current || dirty[0] & /*$theme*/ 4 && div2_class_value !== (div2_class_value = /*$theme*/ ctx[2].body)) {
				attr(div2, "class", div2_class_value);
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div2);
			}

			destroy_each(each_blocks_1, detaching);
			destroy_each(each_blocks, detaching);
			/*div2_binding*/ ctx[18](null);
			mounted = false;
			dispose();
		}
	};
}

function instance$1($$self, $$props, $$invalidate) {
	let $slotWidth;
	let $slotDuration;
	let $scrollTime;
	let $_viewDates;
	let $_dayTimeLimits;
	let $_bodyEl;
	let $_sidebarEl;
	let $_headerEl;
	let $theme;
	let $_dayTimes;
	let $_viewResources;
	let { _bodyEl, _headerEl, _sidebarEl, _dayTimes, _dayTimeLimits, _viewResources, _viewDates, scrollTime, slotDuration, slotWidth, theme } = getContext('state');
	component_subscribe($$self, _bodyEl, value => $$invalidate(22, $_bodyEl = value));
	component_subscribe($$self, _headerEl, value => $$invalidate(24, $_headerEl = value));
	component_subscribe($$self, _sidebarEl, value => $$invalidate(23, $_sidebarEl = value));
	component_subscribe($$self, _dayTimes, value => $$invalidate(3, $_dayTimes = value));
	component_subscribe($$self, _dayTimeLimits, value => $$invalidate(21, $_dayTimeLimits = value));
	component_subscribe($$self, _viewResources, value => $$invalidate(4, $_viewResources = value));
	component_subscribe($$self, _viewDates, value => $$invalidate(1, $_viewDates = value));
	component_subscribe($$self, scrollTime, value => $$invalidate(17, $scrollTime = value));
	component_subscribe($$self, slotDuration, value => $$invalidate(20, $slotDuration = value));
	component_subscribe($$self, slotWidth, value => $$invalidate(19, $slotWidth = value));
	component_subscribe($$self, theme, value => $$invalidate(2, $theme = value));
	let el;

	function handleScroll() {
		set_store_value(_headerEl, $_headerEl.scrollLeft = $_bodyEl.scrollLeft, $_headerEl);
		set_store_value(_sidebarEl, $_sidebarEl.scrollTop = $_bodyEl.scrollTop, $_sidebarEl);
	}

	function scrollToTime() {
		let slotTimeLimits = $_dayTimeLimits[$_viewDates[0].getTime()];

		if (slotTimeLimits) {
			$$invalidate(0, el.scrollLeft = (toSeconds($scrollTime) - toSeconds(slotTimeLimits.min)) / toSeconds($slotDuration) * $slotWidth, el);
		}
	}

	function div2_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(0, el);
		});
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*el*/ 1) {
			set_store_value(_bodyEl, $_bodyEl = el, $_bodyEl);
		}

		if ($$self.$$.dirty[0] & /*el, $_viewDates, $scrollTime*/ 131075) {
			if (el) {
				scrollToTime();
			}
		}
	};

	return [
		el,
		$_viewDates,
		$theme,
		$_dayTimes,
		$_viewResources,
		_bodyEl,
		_headerEl,
		_sidebarEl,
		_dayTimes,
		_dayTimeLimits,
		_viewResources,
		_viewDates,
		scrollTime,
		slotDuration,
		slotWidth,
		theme,
		handleScroll,
		$scrollTime,
		div2_binding
	];
}

class Body extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$1, create_fragment$1, safe_not_equal, {}, null, [-1, -1]);
	}
}

/* packages/resource-timeline/src/View.svelte generated by Svelte v4.2.16 */

function create_fragment(ctx) {
	let div1;
	let sidebar;
	let t0;
	let div0;
	let header;
	let t1;
	let body;
	let div0_class_value;
	let div1_class_value;
	let current;
	sidebar = new Sidebar({});
	header = new Header({});
	body = new Body({});

	return {
		c() {
			div1 = element("div");
			create_component(sidebar.$$.fragment);
			t0 = space();
			div0 = element("div");
			create_component(header.$$.fragment);
			t1 = space();
			create_component(body.$$.fragment);
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[0].main);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[0].container);
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			mount_component(sidebar, div1, null);
			append(div1, t0);
			append(div1, div0);
			mount_component(header, div0, null);
			append(div0, t1);
			mount_component(body, div0, null);
			current = true;
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*$theme*/ 1 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[0].main)) {
				attr(div0, "class", div0_class_value);
			}

			if (!current || dirty & /*$theme*/ 1 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[0].container)) {
				attr(div1, "class", div1_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(sidebar.$$.fragment, local);
			transition_in(header.$$.fragment, local);
			transition_in(body.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(sidebar.$$.fragment, local);
			transition_out(header.$$.fragment, local);
			transition_out(body.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div1);
			}

			destroy_component(sidebar);
			destroy_component(header);
			destroy_component(body);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $theme;
	let { theme } = getContext('state');
	component_subscribe($$self, theme, value => $$invalidate(0, $theme = value));
	return [$theme, theme];
}

class View extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

var index = {
	createOptions(options) {
		// Common options
		options.buttonText.resourceTimelineDay = 'timeline';
		options.buttonText.resourceTimelineWeek = 'timeline';
		options.dayHeaderAriaLabelFormat = {
			dateStyle: 'long',
			timeStyle: 'short'
		};
		options.theme.main = 'ec-main';
		options.theme.times = 'ec-times';
		options.theme.container = 'ec-container';
		options.view = 'resourceTimelineWeek';
		options.views.resourceTimelineDay = {
			buttonText: btnTextDay,
			component: View,
			dayHeaderFormat: {weekday: 'long'},
			duration: {days: 1},
			slotDuration: '01:00',
			theme: themeView('ec-timeline ec-resource-day-view'),
			titleFormat: {year: 'numeric', month: 'long', day: 'numeric'}
		};
		options.views.resourceTimelineWeek = {
			buttonText: btnTextWeek,
			component: View,
			displayEventEnd: false,
			duration: {weeks: 1},
			slotDuration: '01:00',
			theme: themeView('ec-timeline ec-resource-week-view')
		};
	},

	createStores(state) {
		if (!('_viewResources' in state)) {
			state._viewResources = viewResources(state);
		}
		state._headerEl = writable(undefined);
		state._dayTimeLimits = dayTimeLimits(state);  // flexible time limits per day
		state._dayTimes = dayTimes(state);
		state._resHs = writable(new Map());  // resource row heights
		state._sidebarEl = writable(undefined);
	}
};

export { index as default };
